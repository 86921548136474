import type * as Sentry from '@sentry/nextjs'
import { z } from 'zod'

export class SilencedError extends Error {}

export const SentryDSN = z.string()
export const SentryEnabled = z
  .enum(['0', '1'])
  .optional()
  .transform((value) => {
    switch (value) {
      case '0':
        return false
      case '1':
        return true
      default:
        return undefined
    }
  })
export const SentryEnvironment = z.enum([
  'development',
  'preview',
  'production',
])

type NextjsOptions = Parameters<typeof Sentry.init>[0]
type BeforeSend = NonNullable<NextjsOptions['beforeSend']>

const Tags = {
  Silenced: 'silenced',
}

export const beforeSend: BeforeSend = (event, hint) => {
  const error = hint.originalException

  if (error instanceof SilencedError) {
    event.tags = {
      ...event.tags,
      [Tags.Silenced]: true,
    }
  }

  return event
}
